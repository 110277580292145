// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import "lightgallery/scss/lightgallery";
@import "lightgallery/scss/lg-thumbnail";
@import "lightgallery/scss/lg-video";


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$green-palette);
$client-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $client-warn: mat.define-palette(mat.$red-palette);

$client-typography: mat.define-typography-config(
  $font-family: "Tajawal",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-primary,
      accent: $client-accent,
      // warn: $client-warn,
    ),
    typography: $client-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

.line-clamp-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 6;
  -webkit-line-clamp: 6;
}

.line-clamp-8 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 8;
  -webkit-line-clamp: 8;
}

.cms-content img {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

#goals li {
  margin-bottom: 1rem;
}

#goals ul,
ol {
  padding-inline-start: 1rem;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down;
  animation-duration: 1s;
}

.fast {
  animation-duration: 200ms;
}

// #channels-section .flickity-viewport {
//   height: 300px !important;
// }
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

.line-clamp-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 6;
  -webkit-line-clamp: 6;
}

.line-clamp-8 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 8;
  -webkit-line-clamp: 8;
}
body {
  overflow-x: auto;
}
